import React from "react"
import styled from "styled-components"
import { primaryColor } from "../../site-config"

const StyledHeading = styled.h2`
  color: ${props => props.color};
  font-weight: normal;
  font-size: 1.5rem;
  margin-top: 0;
  // text-align: center;
`

const StyledH2 = ({ children, color = primaryColor }) => {
  return <StyledHeading color={color}>{children}</StyledHeading>
}

export default StyledH2
