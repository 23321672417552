import React from "react"
import styled from "styled-components"

const StyledDiv = styled.div`
  padding: 0 2rem;
  max-width: 860px;
  margin: 2rem auto;
`

const StyledMain = ({ children }) => {
  return <StyledDiv>{children}</StyledDiv>
}

export default StyledMain
