import React from "react"
import styled from "styled-components"
import { Contact, SectionTitle } from "@hemalr/react-blocks"
import Layout from "../components/layout"
import StyledMain from "../components/styled-main"
import StyledH2 from "../components/styled-h2"
import SEO from "../components/seo"
import {
  email,
  phone,
  mapEmbed,
  mapLink,
  address,
  primaryColor,
} from "../../site-config"

const StyledLi = styled.li`
  margin-bottom: 0.5rem;
`

const StyledContact = styled.div`
  padding: 0 2rem;
  max-width: 880px;
  margin: 0 auto;
`

const ContactUs = () => (
  <Layout>
    <SEO title="Contact Us" href="https://mopodiatry.com.au/contact-us" />
    <StyledContact>
      <Contact
        title={
          <SectionTitle
            text="Get in touch"
            color={primaryColor}
            level="h1"
            size="3rem"
          />
        }
        subTitle={
          <StyledMain style={{ textAlign: "center" }}>
            <h2>
              Don't be shy, come say hi! Give us a call to book in for an
              appointment.
            </h2>
          </StyledMain>
        }
        mapLink={mapLink}
        address={address}
        mapEmbed={mapEmbed}
        email={email}
        phone={phone}
        minColumnWidth="17rem"
        primaryColor={primaryColor}
      />
    </StyledContact>

    <StyledMain>
      <StyledH2>Helpful tips:</StyledH2>
      <ul>
        <StyledLi>There is plenty of parking</StyledLi>
        <StyledLi>
          Wheelchair accessible{" "}
          <span role="img" aria-label="thumbs-up">
            👍
          </span>
        </StyledLi>
      </ul>
      <p>See you soon!</p>
    </StyledMain>
  </Layout>
)

export default ContactUs
